<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> <strong> Brand </strong> Information </CCardHeader>
            <CCardBody>
              <CForm>
                <CInput
                  label="Name"
                  v-model="$v.obj.name.$model"
                  :isValid="checkIfValid('name')"
                  invalidFeedback="This is a required field"
                  horizontal
                />
                <CRow form class="form-group" v-if="obj.id">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Models
                  </CCol>
                  <CCol sm="9">
                    <CDataTable :items="obj.models" :fields="modelFields">
                      <template #show_details="{ item }">
                        <td class="py-2">
                          <CButton
                            color="primary"
                            variant="outline"
                            square
                            size="sm"
                            @click="removeModel(item)"
                          >
                            Remove
                          </CButton>
                        </td>
                      </template>
                    </CDataTable>
                    <CCard>
                      <CCardHeader> Add Model </CCardHeader>
                      <CCardBody>
                        <CInput
                          label="Name"
                          v-model="newModel.name"
                        
                          horizontal
                        />
                        <!-- <CInput
                          label="Short Name"
                          v-model="obj.shortName"
                         
                          horizontal
                        /> -->
                      </CCardBody>
                      <CCardFooter>
                        <CButton
                          color="primary"
                          variant="outline"
                          square
                          size="sm"
                          @click="addModel()"
                        >
                          Add
                        </CButton>
                      </CCardFooter>
                    </CCard>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardFooter>
              <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="!isValid"
                @click="onSubmit"
                ><CIcon name="cil-check-circle" /> Submit</CButton
              >

              <CButton class="ml-1" color="secondary" @click="cancel">
                Cancel
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import BrandApi from "../../lib/brandApi";
import { required, minLength } from "vuelidate/lib/validators";

const modelFields = [
  { key: "name" },
  // { key: "shortName" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "Brand",
  data: () => {
    return {
      newModel: {
        name: "",
        // shortName: "",
      },
      modelFields,
      infoList: [],
      api: new BrandApi(),
      obj: {
        name: "",
      },
      submitted: false,
    };
  },
  mounted() {
    var self = this;
    this.obj = this.getEmptyObj();
    self.resetObj();
  },

  validations: {
    obj: {
      name: {
        required,
        minLength: minLength(2),
      },
    },
  },

  computed: {
    formString() {
      return JSON.stringify(this.obj, null, 4);
    },
    isValid() {
      return !this.$v.obj.$invalid;
    },
    isDirty() {
      return this.$v.obj.$anyDirty;
    },
  },

  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.obj[fieldName];
      return !(field.$invalid || field.$model === "");
    },

    addModel() {
      var self = this;
      this.api
        .addModel(self.obj.id, self.newModel)
        .then((response) => {
          self.newModel.name = "";
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    removeModel(item) {
      console.log(item);
      var self = this;
      this.api
        .removeModel(item)
        .then((response) => {
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    onSubmit() {
      var self = this;
      if (!self.obj.id) {
        this.api
          .create(self.obj)
          .then((response) => {
            // self.$router.push({ path: "/admins/BrandList" });
            self.$router.push({ path: `/admins/Brand/${response.result.id}` });
            self.resetObj();

            // self.$router.push({ path: "/admins/Brand" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        this.api
          .update(self.obj)
          .then((response) => {
            self.$router.push({ path: "/admins/BrandList" });
            // self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    resetObj() {
      var self = this;
      if (self.$route.params.id) {
        this.api
          .get(self.$route.params.id)
          .then((response) => {
            self.obj = response.result;
            console.log(self.obj);
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        self.obj = self.getEmptyObj();
      }
    },

    getEmptyObj() {
      return {
        name: "",
        models: [
          // {
          //   brandId: "",
          //   brand: null,
          //   name: "",
          //   // shortName: null,
          //   id: ""
          // },
          // {
          //   brandId: "",
          //   brand: null,
          //   name: "",
          //   // shortName: null,
          //   id: ""
          // }
        ],
      };
    },
    cancel() {
      this.$router.push({ path: "/admins/BrandList" });
    },
  },
};
</script>

